import NiceModal from '@ebay/nice-modal-react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { constants, STATUS_GATHER_MOBILE_NUMBER } from '@/lib/constants'
import { useAdminMode } from '@/lib/hooks/useAdminMode'
import { getExperimentsService } from '@/lib/services/userService'
import {
  checkCacheStatus,
  getWithExpiry,
  setWithExpiry,
  storageAvailable,
} from '@/lib/utilities/system'
import { addToDataLayer } from '@/lib/utilities/vehicle'
import { storeHasCookieBotLoaded, storeIsFirstRender } from '@/store/reducers/system'
import {
  getUserMarketingPreferences,
  INITIAL_STATE,
  storeExperiment,
  storeFirstTouch,
  storeLastTouch,
  storeQueryString,
  storeUserDotDigitalId,
  storeVisitorKey,
} from '@/store/reducers/user'
import { selectVersion } from '@/store/selectors/system'
import {
  selectIsAuthenticated,
  selectUserMarketingPreferences,
  selectUsersVisitorKey,
  selectUsersVisitorKeyTimestamp,
} from '@/store/selectors/user'

const DebugWidget = dynamic(() => import('@/components/styleguide/DebugWidget'), { ssr: false })
const Toast = dynamic(() => import('@/components/styleguide/Toast'), { ssr: false })
const OneTap = dynamic(() => import('@/components/styleguide/OneTap'), { ssr: false })
const Footer = dynamic(() => import('@/components/styleguide/Footer'))
const AuthenticationModal = dynamic(() => import('@/components/styleguide/AuthenticationModal'), {
  ssr: false,
})

const Layout = ({ children }) => {
  const router = useRouter()
  const dispatch = useDispatch()

  const isAdminMode = useAdminMode()
  const appVersion = useSelector(selectVersion)
  const existingVisitorKey = useSelector(selectUsersVisitorKey)
  const existingVisitorKeyTimestamp = useSelector(selectUsersVisitorKeyTimestamp)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const marketingPreferences = useSelector(selectUserMarketingPreferences)

  const [isFirstRender, setIsFirstRender] = useState(true)

  const getVisitorKey = useCallback(() => {
    let visitorKey = existingVisitorKey
    let timestamp = existingVisitorKeyTimestamp
    const storageExists = storageAvailable('localStorage')
    const keyInStorage = storageExists
      ? localStorage.getItem(constants.localStorageKey.visitorKey)
      : null
    const timestampInStorage = storageExists
      ? localStorage.getItem(constants.localStorageKey.visitorKeyTimestamp)
      : null

    // If visitorKey doesn't exist in store but exists in localStorage
    if (
      visitorKey == null &&
      timestamp == null &&
      storageExists &&
      !!keyInStorage &&
      !!timestampInStorage
    ) {
      visitorKey = keyInStorage
      timestamp = timestampInStorage
    }

    // Create new visitorKey and timestamp if visitorKey or timestamp doesn't exist
    if (visitorKey == null || timestamp == null) {
      visitorKey = uuidv4()
      timestamp = new Date().getTime()
    }

    // Store visitorKey and timestamp to localStorage if it exists
    if (storageExists) {
      saveVisitorKeyToStorage(visitorKey)
      saveVisitorKeyTimestampToStorage(timestamp)
    }

    // Add visitorKey and timestamp to store
    if (visitorKey !== existingVisitorKey) {
      dispatch(storeVisitorKey({ visitorKey, timestamp }))
    }

    // Add visitorKey to the data layer for analytics
    addToDataLayer({
      visitorKey: visitorKey,
    })

    return visitorKey
  }, [dispatch, existingVisitorKey, existingVisitorKeyTimestamp])

  const saveVisitorKeyToStorage = key => {
    return window.localStorage.setItem(constants.localStorageKey.visitorKey, key)
  }

  const saveVisitorKeyTimestampToStorage = stamp => {
    window.localStorage.setItem(constants.localStorageKey.visitorKeyTimestamp, stamp)
  }

  const getExperiment = async (visitorKey = null) => {
    const experiment = await getExperimentsService({ visitorKey })

    return experiment
  }

  const visitorTracking = useCallback(() => {
    const key = getVisitorKey()

    getExperiment(key).then(response => dispatch(storeExperiment(response)))
  }, [dispatch, getVisitorKey])

  const checkChatWidget = useCallback(
    pathname => {
      const hideWidget =
        pathname.includes('/locospace') ||
        pathname.includes('/ad/deal') ||
        pathname.includes('/car-leasing') ||
        pathname.includes('/van-leasing') ||
        pathname === '/'

      if (hideWidget || isAdminMode) {
        document.body.classList.add('hide-chat-widget')
      } else {
        document.body.classList.remove('hide-chat-widget')
      }
    },
    [isAdminMode],
  )

  const checkForIntegrations = useCallback(() => {
    const storageExists = storageAvailable('localStorage')
    const hasDataInQuery = Object.keys(router.query).includes('llit_dd_uid')
    const dataInStorage = storageExists
      ? getWithExpiry(constants.localStorageKey.dotDigitalId)
      : null
    const dataInUrl = hasDataInQuery ? router.query.llit_dd_uid : null

    if (dataInUrl) {
      dispatch(storeUserDotDigitalId(dataInUrl))

      if (storageExists) {
        const sixMonths = 15778800000
        setWithExpiry(constants.localStorageKey.dotDigitalId, dataInUrl, sixMonths)
      }
      window.localStorage.setItem
    }

    if (!dataInUrl && dataInStorage) {
      dispatch(storeUserDotDigitalId(dataInStorage))
    }

    if (!dataInUrl && !dataInStorage) {
      dispatch(storeUserDotDigitalId(null))
    }
  }, [router, dispatch])

  const checkForFirstOrLastTouch = useCallback(() => {
    const storageExists = storageAvailable('localStorage')
    const touchpointsInStorage = storageExists
      ? JSON.parse(window.localStorage.getItem(constants.localStorageKey.touchpoints))
      : null
    const visitorKeyInStorage = storageExists
      ? window.localStorage.getItem(constants.localStorageKey.visitorKey)
      : null
    const params = router.query
    const tags = ['utm_source', 'utm_medium', 'utm_campaign', 'llit_code', 'irclickid', 'gclid']
    let queryStringParams = {}

    for (const param of Object.entries(params)) {
      if (!!param[1] && tags.includes(param[0])) {
        queryStringParams[param[0]] = param[1]
      }
    }

    if (Object.keys(queryStringParams).length > 0) {
      if (storageExists) {
        if (
          (!touchpointsInStorage && visitorKeyInStorage) ||
          (!touchpointsInStorage && !visitorKeyInStorage)
        ) {
          window.localStorage.setItem(
            constants.localStorageKey.touchpoints,
            JSON.stringify({ firstTouch: queryStringParams, lastTouch: queryStringParams }),
          )
          dispatch(storeFirstTouch(queryStringParams))
          dispatch(storeLastTouch(queryStringParams))
        }

        if (touchpointsInStorage && visitorKeyInStorage) {
          window.localStorage.setItem(
            constants.localStorageKey.touchpoints,
            JSON.stringify({
              firstTouch: touchpointsInStorage.firstTouch,
              lastTouch: queryStringParams,
            }),
          )
          dispatch(storeFirstTouch(touchpointsInStorage.firstTouch))
          dispatch(storeLastTouch(queryStringParams))
        }
      } else {
        dispatch(storeFirstTouch(queryStringParams))
        dispatch(storeLastTouch(queryStringParams))
      }
    } else if (storageExists) {
      if (touchpointsInStorage && visitorKeyInStorage) {
        dispatch(storeFirstTouch(touchpointsInStorage.firstTouch))
        dispatch(storeLastTouch(touchpointsInStorage.lastTouch))
      }

      if (!touchpointsInStorage) {
        window.localStorage.setItem(
          constants.localStorageKey.touchpoints,
          JSON.stringify(INITIAL_STATE.touchpoints),
        )
      }
    }
  }, [dispatch, router.query])

  const storeQueryStringParams = useCallback(() => {
    const params = Object.fromEntries(new URLSearchParams(router.asPath.split('?')[1]))

    dispatch(storeQueryString(params))
  }, [dispatch, router.asPath])

  useEffect(() => {
    dispatch(storeIsFirstRender(isFirstRender))
  }, [dispatch, isFirstRender])

  useEffect(() => {
    if (isAuthenticated && marketingPreferences.length === 0) {
      dispatch(getUserMarketingPreferences())
    }
  }, [marketingPreferences, dispatch, isAuthenticated])

  useEffect(() => {
    if (router.query['social-sign-up']) {
      if (storageAvailable('localStorage')) {
        if (!window.localStorage.getItem(constants.localStorageKey.userProfileBuilder)) {
          NiceModal.show(AuthenticationModal, {
            initialStatus: STATUS_GATHER_MOBILE_NUMBER,
          }).then(() => {
            return router.push(router.pathname, undefined, { shallow: true })
          })
        }
      }
    }
  }, [router])

  useEffect(() => {
    const handleRouteChange = () => {
      if (process.env.NEXT_PUBLIC_ENVIRONMENT !== constants.environments.PRODUCTION) {
        // eslint-disable-next-line no-console
        console.log(`Google Analytics - pageview: ${router.asPath}`)
      }

      addToDataLayer({
        event: 'Pageview',
        pagePath: router.asPath,
        firstViewed: isFirstRender,
      })

      if (typeof window.dmPt !== 'undefined') {
        // Dot Digital page view tracking
        window.dmPt('track')
      }

      setIsFirstRender(false)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [dispatch, router, isFirstRender])

  useEffect(() => {
    if (router.isReady) {
      storeQueryStringParams()
      checkForFirstOrLastTouch()
      checkForIntegrations()
    }
  }, [checkForFirstOrLastTouch, storeQueryStringParams, checkForIntegrations, router.isReady])

  useEffect(() => {
    if (router.isReady) {
      visitorTracking()
    }
  }, [visitorTracking, router.isReady])

  useEffect(() => {
    if (router.isReady) {
      checkChatWidget(router.asPath)
    }
  }, [checkChatWidget, router])

  useEffect(() => {
    if (router.isReady) {
      checkCacheStatus(appVersion)
    }
  }, [router.isReady, appVersion])

  useEffect(() => {
    if (!isAdminMode) {
      const handleCookiebotLoad = () => dispatch(storeHasCookieBotLoaded(true))

      window.addEventListener('CookiebotOnLoad', handleCookiebotLoad)

      return () => {
        window.removeEventListener('CookiebotOnLoad', handleCookiebotLoad)
      }
    }
  }, [dispatch, isAdminMode])

  return (
    <>
      <Script
        id="google-consent"
        data-cookieconsent="ignore"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments)
          }
          gtag("consent", "default", {
            ad_storage: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "granted",
            wait_for_update: 500
          });
          gtag("set", "ads_data_redaction", true);
          gtag("set", "url_passthrough", true);`,
        }}
      />
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        data-cookieconsent="ignore"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}');
          `,
        }}
      />
      {!isAdminMode && (
        <Script
          id="cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid={process.env.NEXT_PUBLIC_COOKIEBOT_ID}
          data-blockingmode="auto"
        />
      )}
      <Script
        id="original-location"
        data-cookieconsent="ignore"
        dangerouslySetInnerHTML={{
          __html:
            'window.dataLayer = window.dataLayer || []; window.dataLayer.push({ "originalLocation": document.location.protocol + \'//\' + document.location.hostname + document.location.pathname + document.location.search });',
        }}
      />
      <Script
        id="first-viewed"
        data-cookieconsent="ignore"
        dangerouslySetInnerHTML={{
          __html:
            'window.dataLayer = window.dataLayer || []; window.dataLayer.push({ "firstViewed": true });',
        }}
      />
      {process.env.NEXT_PUBLIC_ENVIRONMENT === constants.environments.PRODUCTION &&
        process.env.NEXT_PUBLIC_ZENDESK_KEY && (
          <Script
            id="ze-snippet"
            strategy="lazyOnload"
            src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_KEY}`}
          />
        )}
      <Script
        id="dot-digital"
        dangerouslySetInnerHTML={{
          __html:
            "(function(w,d,u,t,o,c){w['dmtrackingobjectname']=o;c=d.createElement(t);c.async=1;c.src=u;t=d.getElementsByTagName(t)[0];t.parentNode.insertBefore(c,t);w[o]=w[o]||function(){(w[o].q=w[o].q||[]).push(arguments);};})(window, document, '//static.trackedweb.net/js/_dmptv4.js', 'script', 'dmPt');window.dmPt('create', 'DM-1014276788-01', 'leaseloco.com');window.dmPt('track');",
        }}
      />
      <Script
        id="trustpilot-widget"
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />
      <div className="global-scroll-area">
        <OneTap />
        {children}
      </div>
      <Toast />
      <Footer />
      <DebugWidget />
    </>
  )
}

export default Layout
